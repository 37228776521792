declare global {
    interface Window {
        __settings: {
            authConfig: {
                appScope: string,
                tenantId: string,
                clientId: string,
                redirectUri: string,
            },
            authConfigUSS: {
                appScope: string,
                tenantId: string,
                apiKey: string,
            },
            endpoints: {
                searchSWs: string,
                getSW: string,
                getSWImage: string,
                createJob: string,
                updateJob: string,
                shareCompletedJob: string,
                getMyJobs: string,
                getJob: string,
                removeSelfFromJob: string,
                uploadResponses: string,
                getResponses: string,
                searchResponses: string,
                uploadComments: string,
                getComments: string,
                searchComments: string,
                completeJob: string,
                sweetImporter: string,
                jobLog: string,
                getJobTemplates: string,
                cloneJobTemplate: string,
                cancelJob: string,
                reopenJob: string,
                printJob: string,
                getPaperExecutions: string,
                uploadPaperExecution: string,
                uploadStepResponseImage: string,
                uploadCommentAttachment: string,
                getConnectivityStatus: string,
                resetSWStepResponses: string,
                saveShowAllECLs: string,
                resetJobPaperExecution: string,
                duplicateJobSW: string,
                addJobDoc: string,
                removeJobDoc: string,
                masterData: {
                    getCountries: string,
                    getLanguages: string,
                    getGeoUnits: string,
                    getOrganizations: string,
                    getSubBusinessLines: string,
                    getSafetyCategories: string,
                    searchActivityWorkflows: string,
                    searchCompetencyElements: string,
                    searchEquipment: string,
                    searchEpicEquipment: string,
                    searchBusinessRoles: string,
                    searchProductCenters: string,
                    searchEquivalentSWs: string,
                    searchOperatingEnvironments: string,
                    searchServiceTypes: string,
                    searchTasks: string,
                    searchCustomers: string,
                    getOwningPlants: string,
                    getPerformingPlants: string,
                    searchWorkCenters: string,
                    searchMaterials: string,
                    getCountriesGeoUnit: string
                },
                USS: {
                    validate: string,
                    launch: string,
                },
                users: {
                    createUser: string,
                    getAADUsers: string,
                    getUsers: string,
                    getUser: string,
                    getUsersJobs: string,
                    updateUser: string,
                    setUserPIN: string,
                    confirmTCC: string,
                    getUserJobCreationPreference: string,
                    updateUserJobCreationPreference: string,
                    getUserPINs: string,
                },
                swUserFeedback: {
                    save: string,
                    get: string,
                },
                ITHelp: {
                    portal: string
                }
            },
            serviceWorker: {
                isEnabled: string,
            },
            indexedDb: {
                dropCreateOnVersionDiff: boolean,
            }
        }
    }
}

const config = window.__settings || {};

// Parse the values that are not strings.
config.indexedDb.dropCreateOnVersionDiff = parseAsBoolean(config.indexedDb.dropCreateOnVersionDiff, "indexedDb.dropCreateOnVersionDiff");

validateObjNoPlaceholders(config, 'config');

function validateObjNoPlaceholders(obj: any, keyPath: string) {
    if (typeof obj === 'object' && obj !== null) {
        Object.keys(obj)
            .forEach(key => {
                validateObjNoPlaceholders(obj[key], keyPath + `.${key}`);
            });
    } else if (obj !== null
        && typeof obj === "string"
        && obj.toString().indexOf('#{') === 0) {
        throw new Error(`The setting named '${keyPath}' in settings.json is a placeholder and must be overwritten by a similar entry in local.settings.json.`);
    }
}

function parseAsBoolean(value: any, settingName: string) {
    if (value === ""
        || value === undefined
        || value === null) {
        throw new Error(`The setting named '${settingName}' is empty, null, or undefined. Please check settings.json and your local.settings.json file.`);
    }

    return value.toLowerCase() === "true";
}

export default config;
