import config from "config";
import { authPostJson, authGetJson, authPostFormData } from "utilities/msalFetches";
import { throwIfResponseError } from "apis/apiUtilities";
import { formatGetJobResponsesResponse, formatGetPaperExecutionsResponse, formatGetStepCommentsResponse, formatGetSWUserFeedbacksResponse, formatSaveAllECLsequest, formatUploadJobPaperExecutionRequest, formatSaveSWUserFeedbackRequest, formatUploadPaperExecutionRequest, formatUploadResponsesRequest, formatUploadStepCommentsRequest, formatDuplicateSWs } from "./ExecutionFormatters";
import { IDuplicateJobSW, IDuplicateSWRequest, IGetJobResponsesResult, IGetPaperExecutionResponse, IJobPaperExecution, IPaperExecution, IStepComment, IStepCommentResponse, IStepResponse, IUserImageData } from "interfaces/execution/executionInterfaces";
import { ISWPJobLogItem, ISWUserFeedback } from "interfaces/jobs/JobInterfaces";
import { OfflineFetchError } from "utilities/errors/OfflineFetchError";
import { IStepIdentifier } from "store/execution/executionTypes";
import { dataURItoBlob } from "utilities/fileUtilities";

class ExecutionApi {
  public async uploadResponses(jobId: number,
    stepResponses: IStepResponse[],
    imgData: IUserImageData[]): Promise<boolean> {
    let response: Response;
    let request = formatUploadResponsesRequest(jobId, stepResponses, imgData);

    try {
      response = await authPostJson(config.endpoints.uploadResponses,
        JSON.stringify(request));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    return true;
  }

  public async getResponses(jobId: number,
    stepIds?: IStepIdentifier[]): Promise<IGetJobResponsesResult> {
    let response: Response;

    try {
      if (stepIds?.length) {
        response = await authPostJson(config
          .endpoints
          .searchResponses
          .replace("{jobId}", jobId.toString()),
          JSON.stringify({
            StepsFilter: stepIds.map(x => ({
              JobSWId: x.jobSWId,
              StepId: x.stepId,
            })),
          }));
      } else {
        response = await authGetJson(config
          .endpoints
          .getResponses
          .replace("{jobId}", jobId.toString()));
      }
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    if (response.status === 204) {
      return {
        stepResponses: [],
        images: [],
      };
    }

    return formatGetJobResponsesResponse(await response.json());
  }

  public async uploadCompletion(jobId: number,
    deviationMessage: string,
    timestamp: Date,
    email: string): Promise<boolean> {
    let response: Response;

    try {
      response = await authPostJson(config.endpoints.completeJob.replace("{jobId}", jobId.toString()),
        JSON.stringify({
          DeviationMessage: deviationMessage,
          Timestamp: timestamp,
          UserEmail: email,
        }));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    return true;
  }

  public async uploadCancellation(jobId: number,
    timestamp: Date,
    userEmail: string): Promise<boolean> {
    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .cancelJob
        .replace("{jobId}",
          jobId.toString()),
        JSON.stringify({
          UserEmail: userEmail,
          Timestamp: timestamp,
        })
      );
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    return true;
  }

  public async uploadJobHistoryLog(jobId: number,
    logItems: ISWPJobLogItem[]): Promise<boolean> {

    let request = logItems.map(l => ({
      Action: l.action,
      Timestamp: l.timestamp,
      UserEmail: l.userEmail,
      UserName: l.userName,
    }));

    let response: Response;

    try {
      response = await authPostJson(config.endpoints.jobLog.replace("{jobId}", jobId.toString()),
        JSON.stringify(request));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    return true;
  }

  public async uploadComments(jobId: number,
    comments: IStepComment[]): Promise<void> {
    let response: Response;
    let request = await formatUploadStepCommentsRequest(comments);

    try {
      response = await authPostJson(config
        .endpoints
        .uploadComments
        .replace("{jobId}",
          jobId.toString()),
        JSON.stringify(request));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async getStepComments(jobId: number,
    commentGuids?: string[]): Promise<IStepCommentResponse> {
    let response: Response;

    try {
      if (commentGuids
        && commentGuids.length) {
        response = await authPostJson(config
          .endpoints
          .searchComments
          .replace("{jobId}",
            jobId.toString()),
          JSON.stringify(commentGuids));
      } else {
        response = await authGetJson(config
          .endpoints
          .getComments
          .replace("{jobId}",
            jobId.toString()));
      }
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    return formatGetStepCommentsResponse(await response.json());
  }

  public async getPaperExecutions(jobId: number): Promise<IGetPaperExecutionResponse[]> {

    let response: Response;

    try {
      response = await authGetJson(config.endpoints.getPaperExecutions.replace("{jobId}", jobId.toString()));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    if (response.status === 204) {
      return [];
    }

    return formatGetPaperExecutionsResponse(await response.json());
  }

  public async uploadPaperExecution(paperExec: IPaperExecution,
    imageDataUri: string,
    jobId: number): Promise<void> {
    const req = formatUploadPaperExecutionRequest(paperExec,
      imageDataUri);

    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .uploadPaperExecution
        .replace("{jobId}",
          jobId.toString()),
        JSON.stringify(req));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async uploadJobPaperExecution(paperExec: IJobPaperExecution,
    imageDataUri: string,
    jobId: number): Promise<void> {
    const req = formatUploadJobPaperExecutionRequest(paperExec,
      imageDataUri);

    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .uploadPaperExecution
        .replace("{jobId}",
          jobId.toString()),
        JSON.stringify(req));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async uploadUserImage(filename: string,
    dataUri: string,
    jobId: number): Promise<void> {
    let response: Response;

    try {
      const url = config
        .endpoints
        .uploadStepResponseImage
        .replace("{jobId}",
          jobId.toString())
        .replace("{filename}",
          filename);

      let formData = new FormData();

      formData.append("File",
        dataURItoBlob(dataUri),
        filename);

      response = await authPostFormData(url, formData);
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async uploadCommentAttachment(filename: string,
    dataUri: string,
    jobId: number): Promise<void> {
    let response: Response;

    try {
      const url = config
        .endpoints
        .uploadCommentAttachment
        .replace("{jobId}",
          jobId.toString())
        .replace("{filename}",
          filename);

      let formData = new FormData();

      formData.append("File",
        dataURItoBlob(dataUri),
        filename);

      response = await authPostFormData(url, formData);
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async uploadJobDoc(filename: string,
    dataUri: string,
    jobId: number): Promise<void> {
    let response: Response;

    try {
      const url = config
        .endpoints
        .addJobDoc
        .replace("{jobId}",
          jobId.toString())
        .replace("{filename}",
          encodeURIComponent(filename));

      let formData = new FormData();

      formData.append("File",
        dataURItoBlob(dataUri),
        filename);

      response = await authPostFormData(url, formData);
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async deleteJobDoc(filename: string,
    jobId: number): Promise<void> {
    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .removeJobDoc
        .replace("{jobId}",
          jobId.toString())
        .replace("{filename}",
          encodeURIComponent(filename)), null);
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }


  public async saveOnlineSWUserFeedback(feedbacks: ISWUserFeedback[]): Promise<void> {
    const req = formatSaveSWUserFeedbackRequest(feedbacks);
    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .swUserFeedback
        .save,
        JSON.stringify(req));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async saveShowAllECLs(jobId: number, showAllECLsValue: boolean, userEmail?: string, timestamp?: Date): Promise<void> {
    const req = formatSaveAllECLsequest(jobId, showAllECLsValue, userEmail, timestamp);
    let response: Response;

    try {
      response = await authPostJson(config.endpoints.saveShowAllECLs.replace("{jobId}", jobId.toString()),
        JSON.stringify(req));

    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async getOnlineSWUserFeedbacks(jobId: number, createdBy: string): Promise<ISWUserFeedback[]> {
    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .swUserFeedback
        .get,
        JSON.stringify(
          {
            JobId: jobId,
            CreatedBy: createdBy
          }));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);

    let responseObj = await response.json();

    return formatGetSWUserFeedbacksResponse(responseObj);
  }

  public async resetOnlineJobPaperExecution(jobId: number): Promise<void> {
    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .resetJobPaperExecution
        .replace("{jobId}",
          jobId.toString()), null);

    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async resetOnlineSWStepResponses(jobSWIds: number[]): Promise<void> {
    let response: Response;

    try {
      response = await authPostJson(config
        .endpoints
        .resetSWStepResponses,
        JSON.stringify({ JobSWIds: jobSWIds }));
    } catch (err: any) {
      throw new OfflineFetchError(err.message);
    }

    await throwIfResponseError(response);
  }

  public async duplicateSWsAddInJob(sws: IDuplicateSWRequest[], jobId: number): Promise<IDuplicateJobSW[]> {
    let response: Response;
    try {
      let swResponses: any[] = [];
      sws.forEach(s => {
        swResponses.push(formatDuplicateSWs(s, jobId));
      })

      response = await authPostJson(config
        .endpoints
        .duplicateJobSW,
        JSON.stringify(
          swResponses
        ));
    }

    catch (err: any) {
      throw new OfflineFetchError(err.message);
    }
    await throwIfResponseError(response);

    let responseObj = await response.json();
    return responseObj.DuplicateSWs;
  }
}

export default new ExecutionApi();