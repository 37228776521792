import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IOperation } from "interfaces/operations/OperationInterfaces";

export interface ISearchSWsOptions {
  searchTerm: string,
  typesFilter: IMasterDataItem[],
  globalOrLocalFilter: IMasterDataItem[],
  alignmentsFilter: IMasterDataItem[],
  languagesFilter: IMasterDataItem[],
  equivalentSWsFilter: IMasterDataItem[],
  tasksFilter: IMasterDataItem[],
  activityWorkflowsFilter: IMasterDataItem[],
  owningOrgsFilter: IMasterDataItem[],
  performingOrgsFilter: IMasterDataItem[],
  subBusinessLinesFilter: IMasterDataItem[],
  serviceTypesFilter: IMasterDataItem[],
  operatingEnvironmentsFilter: IMasterDataItem[],
  equipmentFilter: IMasterDataItem[],
  countriesFilter: IMasterDataItem[],
  competencyElementsFilter: IMasterDataItem[],
  safetyCategoriesFilter: IMasterDataItem[],
  productCentersFilter: IMasterDataItem[],
  businessRolesFilter: IMasterDataItem[],
  epicEquipmentFilter: IMasterDataItem[],
  geoUnitsFilter: IMasterDataItem[],
  customers: IMasterDataItem[],
  maintenanceTaskIdsFilter: string,
  owningPlants: IMasterDataItem[],
  performingPlants: IMasterDataItem[],
  workCenters: IMasterDataItem[],
  materials: IMasterDataItem[],
}

export interface ISWSearchResult {
  items: ISWSearchResultItem[],
  loadOperation?: IOperation,
  totalCount: number,
}

export interface ISWSearchResultItem {
  swId: string,
  title: string | null,
  description?: string | null,
  version: string,
}

export interface IGetSWResponse {
  images: ISWAttachmentRef[],
  refDocs: ISWAttachmentRef[],
  swId: string,
  version: string,
  documentAbsoluteUri: string,
}

export interface ISWAttachmentRef {
  absoluteUri: string,
  filename: string,
}

export interface ISW {
  docType: string,
  docVersion: string,
  id: string,
  version: string,
  type?: SWTypes,
  title: string,
  description?: string | null,
  ppe: IPPE[],
  notices: INotice[],
  refDocs: IReferenceDoc[],
  steps: IStep[],
  hasCriticalSteps: boolean,
}

export interface IOfflineSW {
  swJson: string,
  jobId: number,
  swId: string,
  swVersion: string,
}

export interface IPPE {
  ppe: string,
  isLocalFile?: boolean,
}

export enum SWTypes {
  Unspecified = "Unspecified",
  CL = "CL",
  ECL = "ECL",
  SWI = "SWI",
  TLMSWI = "TLMSWI",
  LCL = "LCL",
  TLMRC = "TLMRC",
  REPOSWI = "REPOSWI",
  TIMERC = "TIMERC",
  MFGSWI = "MFGSWI",
  MFGCL = "MFGCL",
  MFGRC = "MFGRC",
}

export interface INotice {
  id: string,
  text: string,
  type: NoticeTypes,
  sortOrder: number,
  filename: string,
}

export enum NoticeTypes {
  Info = "Info",
  Warning = "Warning",
  Caution = "Caution",
}

export interface IReferenceDoc {
  id: string,
  label: string,
  refDocData: string,
  sortOrder?: number,
  type: string,
  tableData?: IRefDocTableComponent,
}

export interface IRefDocTableComponent {
  rowCount: number,
  colCount: number,
  cells: ITableComponentCell[],
}

export interface ITableComponentCell {
  rowIndex: number,
  colIndex: number,
  value: string,
  rowSpan: number,
  colSpan: number,
}

export interface IStep {
  id: string,
  parentId: string | undefined,
  title: string,
  number: string,
  isConditional: boolean,
  conditionalText: string,
  sortOrder: number,
  isMemoryStep: boolean,
  isCritical: boolean,
  isTask: boolean,
  showStep: boolean,
  components: IStepComponent[],
  children: IStep[],
}

export interface IStepComponent {
  id: string,
  type: StepComponentTypes,
  label: string,
  data: null
  | INoticeComponentData
  | IImageComponentData
  | ITimeImageComponentData
  | ISelectComponentData
  | INumberInputData
  | IMicrosoftStreamVideoData
  | ILinkData
  | ITableComponentData
  | IPPEComponent
  | IRichTextParagraphData
  | IVideoComponentData
  | IYesNoComponentData
  | IPassFailComponentData
  | IMultiselectComponentData
  sortOrder: number,
}

export enum StepComponentTypes {
  DateInput = "DateInput",
  DateTimeInput = "DateTimeInput",
  Image = "Image",
  TimeImage = "TimeImage",
  MultiSelectInput = "MultiSelectInput",
  Notice = "Notice",
  NumberInput = "NumberInput",
  PhotoInput = "PhotoInput",
  SelectInput = "SelectInput",
  SignatureInput = "SignatureInput",
  TextInput = "TextInput",
  YesNoInput = "YesNoInput",
  MicrosoftStreamVideo = "MicrosoftStreamVideo",
  Link = "Link",
  Table = "Table",
  EnhancedTable = "EnhancedTable",
  PPE = "PPE",
  Video = "Video",
  RichTextParagraph = "RichTextParagraph",
  VideoInput = "VideoInput",
  QualityControlPoint = "QualityControlPoint",
  Audio = "Audio",
  AudioInput = "AudioInput",
  PassFailInput = "PassFailInput",
  FormulaNumerical = "FormulaNumerical",
}

export interface INoticeComponentData {
  type: NoticeTypes,
  filename: string,
}

export interface IYesNoComponentData {
  expectedValue: string | undefined,
  nonConform: string,
}

export interface IPassFailComponentData {
  expectedValue: string | undefined,
  nonConform: string,
}

export interface IImageComponentData {
  filename: string,
}

export interface ITimeImageComponentData {
  filename: string,
}

export interface IMultiselectComponentData {
  options: string[],
  expectedValue: string[] | undefined,
  nonConform: string,
}

export interface ISelectComponentData {
  options: string[],
  expectedValue: string | undefined,
  nonConform: string,
}

export interface INumberInputData {
  uom: string,
  lowerLimit: number | undefined,
  expectedValue: number | undefined,
  upperLimit: number | undefined,
  nonConform: string,
}

export interface IRichTextParagraphData {
  html: string,
}

export interface IMicrosoftStreamVideoData {
  videoGuid: string,
}

export interface ILinkData {
  url: string,
}

export interface ITableComponentData {
  rowCount: number,
  colCount: number,
  cells: ITableComponentCellData[],
}

export interface ITableComponentCellData {
  rowIndex: number,
  colIndex: number,
  value: string,
  rowSpan: number,
  colSpan: number,
}

export interface IPPEComponent {
  ppe: string[],
}

export interface IVideoComponentData {
  filename: string,
}

export interface IAudioComponentData {
  filename: string,
}