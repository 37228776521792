import { createAction } from "@reduxjs/toolkit";
import { IMasterDataSection, MasterDataSectionNames } from "./masterDataTypes";

export const loadLanguages = createAction("masterData/loadLanguages");
export const loadCountries = createAction<MasterDataPayload>("masterData/loadCountries");
export const loadGeoUnits = createAction<MasterDataPayload>("masterData/loadGeoUnits");
export const loadOrganizations = createAction<MasterDataPayload>("masterData/loadOrganizations");
export const loadSubBusinessLines = createAction<MasterDataPayload>("masterData/loadSubBusinessLines");
export const loadSafetyCategories = createAction("masterData/loadSafetyCategories");
export const searchActivityWorkflows = createAction<string>("masterData/searchActivityWorkflows");
export const searchCompetencyElements = createAction<string>("masterData/searchCompetencyElements");
export const searchEquipment = createAction<string>("masterData/searchEquipment");
export const searchEpicEquipment = createAction<string>("masterData/searchEpicEquipment");
export const searchBusinessRoles = createAction<string>("masterData/searchBusinessRoles");
export const searchProductCenters = createAction<string>("masterData/searchProductCenters");
export const searchEquivalentSWs = createAction<string>("masterData/searchEquivalentSWs");
export const searchOperatingEnvironments = createAction<string>("masterData/searchOperatingEnvironments");
export const searchServiceTypes = createAction<string>("masterData/searchServiceTypes");
export const searchCustomers = createAction<{ searchTerm: string, includeAll: boolean }>("masterData/searchCustomers");
export const searchTasks = createAction<string>("masterData/searchTasks");
export const setSection = createAction<{
  name: MasterDataSectionNames,
  section: IMasterDataSection
}>("masterData/setSection");
export const loadOwningPlants = createAction("masterData/loadOwningPlants");
export const loadPerformingPlants = createAction("masterData/loadPerformingPlants");
export const searchWorkCenters = createAction<string>("masterData/searchWorkCenters");
export const searchMaterials = createAction<string>("masterData/searchMaterials");
export const clearSearchedMasterDataItems = createAction("masterData/clearSearchedMasterDataItems");
export const loadCountriesGeoUnit = createAction<{includeAll: boolean, geoUnitCode: string}>("masterData/loadCountriesGeoUnit");
type MasterDataPayload = { includeAll: boolean } | undefined;