import { IJobSchedule, ISWPJobTeamMember, SWPJobType } from "interfaces/jobs/JobInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { IOperation } from "interfaces/operations/OperationInterfaces";
import { ISearchSWsOptions, ISWSearchResult } from "interfaces/sw/SWInterfaces";

export interface IManageJobState {
  isFetching: boolean,
  isSaving: boolean,
  job: IInProgressJob,
  getErrorMessage: string,
  saveErrorMessage: string,
  wasSaveSuccessful: boolean | null,
  searchSWData: ISearchSWData,
  currentView: ManageJobViews,
  jobDocPdfOperation: IOperation | undefined,
}

export interface IInProgressJob {
  id: number | null,
  title: string,
  jobNumber: string,
  isDemo: boolean,
  jobDocs: IJobDocs[],
  sws: IManageJobSW[],
  team: ISWPJobTeamMember[],
  type: SWPJobType,
  country: IMasterDataItem | undefined,
  org: IMasterDataItem | undefined,
  geoUnit: IMasterDataItem | undefined,
  customer: IMasterDataItem | undefined,
  subBusinessLine: IMasterDataItem | undefined,
  schedule?: IJobSchedule,
  assetDetails?: string
}

export interface ISearchSWData {
  filterFields: ISearchSWsOptions,
  swData: ISWSearchResult,
  index: number,
}

export interface IJobDocs {
  fileName: string,
  fileContent: string,
  fileSize: number,
}

export interface IManageJobSW {
  jobSWId?: number,
  swId: string,
  title: string | null,
  description?: string | null,
  version: string,
  listGuid: string,
  sortOrder: number,
}

export enum ManageJobViews {
  Attrs = "Job Attributes",
  Search = "Search Standard Work",
  SW = "Manage Standard Work",
  Docs = "Add Documents",
  Team = "Team",
}