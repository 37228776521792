import { takeLatest, put, all, call } from "redux-saga/effects";
import { getResponseErrorMessage } from "utilities/validationErrorHelpers";
import MasterDataApi from "apis/masterData/MasterDataApi";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import {
  setSection,
  loadOrganizations,
  loadCountries,
  loadGeoUnits,
  loadLanguages,
  loadSafetyCategories,
  searchActivityWorkflows,
  searchCompetencyElements,
  searchEquipment,
  searchEquivalentSWs,
  searchOperatingEnvironments,
  searchServiceTypes,
  searchTasks,
  searchEpicEquipment,
  searchBusinessRoles,
  searchProductCenters,
  searchCustomers,
  loadSubBusinessLines,
  loadOwningPlants,
  loadPerformingPlants,
  searchWorkCenters,
  searchMaterials,
  clearSearchedMasterDataItems,
  loadCountriesGeoUnit
} from "./masterDataActions";
import { MasterDataSectionNames } from "./masterDataTypes";
import { Action } from "@reduxjs/toolkit";

export default function* watchMasterDataSagas() {
  yield all([
    watchLoadCountries(),
    watchLoadLanguages(),
    watchLoadGeoUnits(),
    watchLoadOrganizations(),
    watchLoadSubBusinessLines(),
    watchLoadSafetyCategories(),
    watchSearchActivityWorkflows(),
    watchSearchEquivalentSWs(),
    watchSearchCompetencyElements(),
    watchSearchEquipment(),
    watchSearchEpicEquipment(),
    watchSearchCustomers(),
    watchSearchProductCenters(),
    watchSearchBusinessRoles(),
    watchSearchOperatingEnvironments(),
    watchSearchServiceTypes(),
    watchSearchTasks(),
    watchLoadOwningPlants(),
    watchLoadPerformingPlants(),
    watchSearchWorkCenters(),
    watchSearchMaterials(),
    watchClearSearchedMasterDataItems(),
    watchLoadCountriesGeoUnit()
  ]);
}

function* watchClearSearchedMasterDataItems() {
  yield takeLatest(clearSearchedMasterDataItems, clearSearchedMasterDataItemsAsync);
}

function* watchLoadCountries() {
  yield takeLatest(loadCountries, loadCountriesAsync);
}

function* watchLoadLanguages() {
  yield takeLatest(loadLanguages, loadLanguagesAsync);
}

function* watchLoadGeoUnits() {
  yield takeLatest(loadGeoUnits, loadGeoUnitsAsync);
}

function* watchLoadOrganizations() {
  yield takeLatest(loadOrganizations, loadOrganizationsAsync);
}

function* watchLoadSubBusinessLines() {
  yield takeLatest(loadSubBusinessLines, loadSubBusinessLinesAsync);
}

function* watchLoadSafetyCategories() {
  yield takeLatest(loadSafetyCategories, loadSafetyCategoriesAsync);
}

function* watchSearchActivityWorkflows() {
  yield takeLatest(searchActivityWorkflows, searchActivityWorkflowsAsync);
}

function* watchSearchEquivalentSWs() {
  yield takeLatest(searchEquivalentSWs, searchEquivalentSWsAsync);
}

function* watchSearchCompetencyElements() {
  yield takeLatest(searchCompetencyElements, searchCompetencyElementsAsync);
}

function* watchSearchEquipment() {
  yield takeLatest(searchEquipment, searchEquipmentAsync);
}

function* watchSearchCustomers() {
  yield takeLatest(searchCustomers, searchCustomersAsync);
}

function* watchSearchEpicEquipment() {
  yield takeLatest(searchEpicEquipment, searchEpicEquipmentAsync);
}

function* watchSearchBusinessRoles() {
  yield takeLatest(searchBusinessRoles, searchBusinessRolesAsync);
}

function* watchSearchProductCenters() {
  yield takeLatest(searchProductCenters, searchProductCentersAsync);
}

function* watchSearchOperatingEnvironments() {
  yield takeLatest(searchOperatingEnvironments, searchOperatingEnvironmentsAsync);
}

function* watchSearchServiceTypes() {
  yield takeLatest(searchServiceTypes, searchServiceTypesAsync);
}

function* watchSearchTasks() {
  yield takeLatest(searchTasks, searchTasksAsync);
}

function* watchLoadPerformingPlants() {
  yield takeLatest(loadPerformingPlants, loadPerformingPlantsAsync);
}

function* watchLoadOwningPlants() {
  yield takeLatest(loadOwningPlants, loadOwningPlantsAsync);
}

function* watchSearchWorkCenters() {
  yield takeLatest(searchWorkCenters, searchWorkCentersAsync);
}

function* watchSearchMaterials() {
  yield takeLatest(searchMaterials, searchMaterialsAsync);
}

function* watchLoadCountriesGeoUnit() {
  yield takeLatest(loadCountriesGeoUnit, loadCountriesGeoUnitAsync);
}

function* loadCountriesAsync(action: Action) {
  if (!loadCountries.match(action)) {
    return;
  }

  yield call(loadMasterDataAsync,
    "countries",
    MasterDataApi.getCountries,
    action.payload?.includeAll);
}

function* loadGeoUnitsAsync(action: Action) {
  if (!loadGeoUnits.match(action)) {
    return;
  }

  yield call(loadMasterDataAsync,
    "geoUnits",
    MasterDataApi.getGeoUnits,
    action.payload?.includeAll);
}

function* loadOrganizationsAsync(action: Action) {
  if (!loadOrganizations.match(action)) {
    return;
  }

  yield call(loadMasterDataAsync,
    "organizations",
    MasterDataApi.getOrganizations,
    action.payload?.includeAll);
}

function* loadSubBusinessLinesAsync(action: Action) {
  if (!loadSubBusinessLines.match(action)) {
    return;
  }

  yield call(loadMasterDataAsync,
    "subBusinessLines",
    MasterDataApi.getSubBusinessLines,
    action.payload?.includeAll);
}

function* loadLanguagesAsync() {
  yield call(loadMasterDataAsync,
    "languages",
    MasterDataApi.getLanguages);
}

function* loadSafetyCategoriesAsync() {
  yield call(loadMasterDataAsync,
    "safetyCategories",
    MasterDataApi.getSafetyCategories);
}

function* searchActivityWorkflowsAsync(action: Action) {
  if (!searchActivityWorkflows.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "activityWorkflows",
    MasterDataApi.searchActivityWorkflows,
    action.payload);
}

function* searchEquivalentSWsAsync(action: Action) {
  if (!searchEquivalentSWs.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "equivalentSWs",
    MasterDataApi.searchEquivalentSWs,
    action.payload);
}

function* searchCompetencyElementsAsync(action: Action) {
  if (!searchCompetencyElements.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "competencyElements",
    MasterDataApi.searchCompetencyElements,
    action.payload);
}

function* searchEquipmentAsync(action: Action) {
  if (!searchEquipment.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "equipment",
    MasterDataApi.searchEquipment,
    action.payload);
}

function* searchCustomersAsync(action: Action) {
  if (!searchCustomers.match(action)) {
    return;
  }

  yield call(searchAllMasterDataAsync,
    "customers",
    MasterDataApi.searchCustomers,
    action.payload.searchTerm,
    action.payload.includeAll);
}

function* searchEpicEquipmentAsync(action: Action) {
  if (!searchEpicEquipment.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "epicEquipment",
    MasterDataApi.searchEpicEquipment,
    action.payload);
}

function* searchBusinessRolesAsync(action: Action) {
  if (!searchBusinessRoles.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "businessRoles",
    MasterDataApi.searchBusinessRoles,
    action.payload);
}

function* searchProductCentersAsync(action: Action) {
  if (!searchProductCenters.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "productCenters",
    MasterDataApi.searchProductCenters,
    action.payload);
}

function* searchOperatingEnvironmentsAsync(action: Action) {
  if (!searchOperatingEnvironments.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "operatingEnvironments",
    MasterDataApi.searchOperatingEnvironments,
    action.payload);
}

function* searchServiceTypesAsync(action: Action) {
  if (!searchServiceTypes.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "serviceTypes",
    MasterDataApi.searchServiceTypes,
    action.payload);
}

function* searchTasksAsync(action: Action) {
  if (!searchTasks.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "tasks",
    MasterDataApi.searchTasks,
    action.payload);
}

function* loadOwningPlantsAsync() {
  yield call(loadMasterDataAsync,
    "owningPlants",
    MasterDataApi.getOwningPlants);
}

function* loadPerformingPlantsAsync() {
  yield call(loadMasterDataAsync,
    "performingPlants",
    MasterDataApi.getPerformingPlants);
}

function* searchWorkCentersAsync(action: Action) {
  if (!searchWorkCenters.match(action)){
    return;
  }
  
  yield call(searchMasterDataAsync,
    "workCenters",
    MasterDataApi.searchWorkCenters,
    action.payload);
}

function* searchMaterialsAsync(action: Action) {
  if (!searchMaterials.match(action)) {
    return;
  }

  yield call(searchMasterDataAsync,
    "materials",
    MasterDataApi.searchMaterials,
    action.payload);
}

function* loadMasterDataAsync(sectionName: MasterDataSectionNames,
  apiMethod: (includeAll: boolean, geoUnitCode?:string) => Promise<IMasterDataItem[]>,
  includeAll?: boolean, geoUnitCode?:string ) {

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = !geoUnitCode? 
    yield call([MasterDataApi, apiMethod], includeAll || false):
    yield call([MasterDataApi, apiMethod], includeAll || false, geoUnitCode);
    
    if (items.length <= 0) {
      let item: IMasterDataItem = {
        guid: "No Data Available",
        value: "No Data Available",
        code: "NDA",
      };
      items.push(item);
    }

    yield put(setSection({
      name: sectionName,
      section: {
        items,
        loadOperation: {
          isWorking: false,
        },
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* clearSearchedMasterDataItemsAsync(action: Action) {
  if (!clearSearchedMasterDataItems.match(action)) {
    return;
  }

  // Later on for all master data which contains the includeAll true or false can be added for fixes
  yield put(setSection({
    name: "subBusinessLines",
    section: {
      items: [],
    },
  }));
}

function* searchMasterDataAsync(sectionName: MasterDataSectionNames,
  apiMethod: (searchTerm: string) => Promise<IMasterDataItem[]>,
  searchTerm: string) {

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, apiMethod], searchTerm);

    yield put(setSection({
      name: sectionName,
      section: {
        items,
        loadOperation: {
          isWorking: false,
        },
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* searchAllMasterDataAsync(sectionName: MasterDataSectionNames,
  apiMethod: (searchTerm: string, includeAll: boolean) => Promise<IMasterDataItem[]>,
  searchTerm: string,
  includeAll: boolean) {

  yield put(setSection({
    name: sectionName,
    section: {
      items: [],
      loadOperation: {
        isWorking: true,
      },
    },
  }));

  try {
    const items: IMasterDataItem[] = yield call([MasterDataApi, apiMethod], searchTerm, includeAll);

    yield put(setSection({
      name: sectionName,
      section: {
        items,
        loadOperation: {
          isWorking: false,
        },
      },
    }));
  } catch (err: any) {
    yield put(setSection({
      name: sectionName,
      section: {
        items: [],
        loadOperation: {
          isWorking: false,
          errorMessage: getResponseErrorMessage(err),
        },
      },
    }));
    return;
  }
}

function* loadCountriesGeoUnitAsync(action: Action) {
  if (!loadCountriesGeoUnit.match(action)) {
    return;
  }

  yield call(loadMasterDataAsync,
     "countries",
     MasterDataApi.getCountriesGeoUnit,
     action.payload?.includeAll,
     action.payload?.geoUnitCode);
 
}
