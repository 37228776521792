import React from "react";
import "./TableComponent.scoped.scss";
import { IStepComponent, ITableComponentData } from "interfaces/sw/SWInterfaces";
import TableComponentCell from "./TableComponentCell";
import { IStepIdentifier } from "store/execution/executionTypes";

interface ITableComponentProps {
  component: IStepComponent,
  swId: string,
  swVersion: string,
  stepInfo: IStepIdentifier,
}

const TableComponent: React.FC<ITableComponentProps> = ({ component, swId, swVersion, stepInfo }) => {
  if (!isTableData(component.data)) {
    return null;
  }

  let { rowCount, colCount, cells } = component.data;

  //build a 2d array here and one by one build objects with colSpan, rowSpan, isDeleted
  let cellArray = new Array(rowCount);
  for (var r = 0; r < cellArray.length; r++) {
    cellArray[r] = new Array(colCount);
    for (var c = 0; c < cellArray[r].length; c++) {
      let cIx = c;
      let rIx = r;

      let cell = cells.filter(x => x.rowIndex === rIx && x.colIndex === cIx)[0];
      if (cell !== undefined) {
        cellArray[rIx][cIx] = { colSpan: cell.colSpan ? cell.colSpan : 1, rowSpan: cell.rowSpan ? cell.rowSpan : 1, deleted: false };
      } else {
        cellArray[rIx][cIx] = { colSpan: 1, rowSpan: 1, deleted: false };
      }
    }
  }

  // figure out which cells should be deleted to handle col spans and row spans
  for (var ra = 0; ra < rowCount; ra++) {
    for (var ca = 0; ca < colCount; ca++) {
      let cell = cellArray[ra][ca];
      if (cell.deleted) {
        continue;
      }

      if (cell.colSpan > 1 && cell.rowSpan > 1) {
        // disable columns and rows??
        for (var i = ca + 1; i < ca + cell.colSpan; i++) {
          let cellToDisable = cellArray[ra][i];
          if (cellToDisable) {
            cellToDisable.deleted = true;
            cellToDisable.rowSpan = 1;
            cellToDisable.colSpan = 1;
          }
        }
        for (var j = ra + 1; j < ra + cell.rowSpan; j++) {
          let cellToDisable = cellArray[j][ca];
          if (cellToDisable) {
            cellToDisable.deleted = true;
            cellToDisable.rowSpan = 1;
            cellToDisable.colSpan = 1;
          }

          for (var z = 1; z < cell.colSpan; z++) {
            let cellToDisable = cellArray[j][ca + z];
            if (cellToDisable) {
              cellToDisable.deleted = true;
              cellToDisable.rowSpan = 1;
              cellToDisable.colSpan = 1;
            }
          }
        }
      } else if (cell.colSpan > 1) {
        // disable columns
        for (var ii = ca + 1; ii < ca + cell.colSpan; ii++) {
          let cellToDisable = cellArray[ra][ii];
          if (cellToDisable) {
            cellToDisable.deleted = true;
            cellToDisable.rowSpan = 1;
            cellToDisable.colSpan = 1;
          }
        }
      } else if (cell.rowSpan > 1) {
        // disable rows
        for (var jj = ra + 1; jj < ra + cell.rowSpan; jj++) {
          let cellToDisable = cellArray[jj][ca];
          if (cellToDisable) {
            cellToDisable.deleted = true;
            cellToDisable.rowSpan = 1;
            cellToDisable.colSpan = 1;
          }
        }
      }
    }
  }

  const colWidth = 100 / colCount;

  return (
    <div
      className="html-content">
      <p>{component.label}</p>
      <table>
        <colgroup>
          {
            [...new Array(colCount)].map((_, cIx) => {
              return <col key={cIx} style={{ width: colWidth + '%' }} />
            })
          }
        </colgroup>
        <tbody>
          {
            [...new Array(rowCount)].map((_, ixr) => (
              <tr key={"r" + ixr}>
                {
                  [...new Array(colCount)].map((_, ixc) => {
                    let cellFormatter = cellArray[ixr][ixc]; // is this cell deleted?
                    if (!cellFormatter.deleted) {
                      let cell = cells.filter(x => x.rowIndex === ixr && x.colIndex === ixc)[0];
                      let cellColSpan = cell !== undefined ? cell.colSpan : 1;
                      let cellRowSpan = cell !== undefined ? cell.rowSpan : 1;

                      return (
                        <td key={"c" + ixc} colSpan={cellColSpan} rowSpan={cellRowSpan}>
                          <TableComponentCell
                            rowKey={ixr}
                            colKey={ixc}
                            cells={cells}
                            swId={swId}
                            swVersion={swVersion}
                            stepInfo={stepInfo}
                            componentId={component.id}
                          />
                        </td>);
                    } else {
                      return undefined;
                    }
                  })
                }
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;

function isTableData(data: any): data is ITableComponentData {
  return data !== null
    && data !== undefined
    && 'cells' in data;
}