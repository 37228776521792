import {
  IManageJobState, ManageJobViews,
} from "./manageJobTypes";
import { createReducer } from "@reduxjs/toolkit";
import {
  setIsGettingJob,
  startNewJob,
  setJob,
  setNumber,
  setTitle,
  setIsDemo,
  addSW,
  removeSW,
  moveSW,
  addTeamMember,
  removeTeamMember,
  setIsSaving,
  setSaveError,
  setGetJobError,
  setSaveSuccess,
  setJobType,
  setCountry,
  setOrg,
  setSearchText,
  setSWSearchResults,
  setStringFilter,
  addFilterItem,
  addFilterItems,
  removeFilterItem,
  clearFilter,
  setGeoUnit,
  setCustomer,
  setSchedule,
  setCurrentJobView,
  addJobDocs,
  removeJobDocs,
  setNewIndex,
  setSubBusinessLine,
  setJobDocPdfOperation,
  setAssetDetails,
} from "./manageJobActions";
import { SWPJobType } from "interfaces/jobs/JobInterfaces";
import { IMasterDataItem } from "interfaces/masterData/masterDataInterfaces";
import { v4 as uuidv4 } from "uuid";

const initialState: IManageJobState = {
  isFetching: false,
  isSaving: false,
  job: {
    id: null,
    title: "",
    jobNumber: "",
    isDemo: false,
    type: SWPJobType.Standard,
    jobDocs: [],
    sws: [],
    team: [],
    org: undefined,
    country: undefined,
    geoUnit: undefined,
    customer: undefined,
    schedule: undefined,
    subBusinessLine: undefined,
  },
  saveErrorMessage: "",
  getErrorMessage: "",
  wasSaveSuccessful: null,
  searchSWData: {
    swData: {
      items: [],
      loadOperation: undefined,
      totalCount: 0,
    },
    index: 0,
    filterFields: {
      searchTerm: "",
      typesFilter: [],
      globalOrLocalFilter: [],
      alignmentsFilter: [],
      languagesFilter: [],
      equivalentSWsFilter: [],
      tasksFilter: [],
      activityWorkflowsFilter: [],
      owningOrgsFilter: [],
      performingOrgsFilter: [],
      subBusinessLinesFilter: [],
      serviceTypesFilter: [],
      operatingEnvironmentsFilter: [],
      equipmentFilter: [],
      countriesFilter: [],
      competencyElementsFilter: [],
      safetyCategoriesFilter: [],
      productCentersFilter: [],
      businessRolesFilter: [],
      epicEquipmentFilter: [],
      customers: [],
      geoUnitsFilter: [],
      owningPlants: [],
      performingPlants: [],
      workCenters: [],
      materials: [],
      maintenanceTaskIdsFilter: "",
    }
  },
  currentView: ManageJobViews.Attrs,
  jobDocPdfOperation: undefined,
};

const manageJobReducer = createReducer(initialState, builder => {
  // Set Is Getting Job
  builder.addCase(setIsGettingJob, (state, action) => {
    state.isFetching = action.payload;
  });

  // Start New Job
  builder.addCase(startNewJob, () => {
    return initialState;
  });

  // Set Job
  builder.addCase(setJob, (state, action) => {
    state.job = {
      ...action.payload,
      sws: action.payload.sws.map(x => ({
        ...x,
        listGuid: uuidv4(),
      })),
    }
  });

  //Set New Index for Loading more data
  builder.addCase(setNewIndex, (state, action) => {
    state.searchSWData.index = action.payload;
  });

  // Set Number
  builder.addCase(setNumber, (state, action) => {
    state.job.jobNumber = action.payload;
  });

  // Set Title
  builder.addCase(setTitle, (state, action) => {
    state.job.title = action.payload;
  });

  // Set Is Demo
  builder.addCase(setIsDemo, (state, action) => {
    state.job.isDemo = action.payload;
  });

  // Set Is Template
  builder.addCase(setJobType, (state, action) => {
    if (state.job.schedule
      && action.payload !== SWPJobType.Recurring) {
      state.job.schedule = undefined;
    }

    state.job.type = action.payload;
  });

  // Set Country
  builder.addCase(setCountry, (state, action) => {
    state.job.country = action.payload;
  });

  // Set Org
  builder.addCase(setOrg, (state, action) => {
    state.job.org = action.payload;
  });

   // Set sub Business Line
   builder.addCase(setSubBusinessLine, (state, action) => {
    state.job.subBusinessLine = action.payload;
  });

  // Set GeoUnit
  builder.addCase(setGeoUnit, (state, action) => {
    state.job.geoUnit = action.payload;
  });

  // Set Customer
  builder.addCase(setCustomer, (state, action) => {
    state.job.customer = action.payload;
  });

  // Set Schedule
  builder.addCase(setSchedule, (state, action) => {
    state.job.schedule = action.payload;
  });

  // Add Job Docs
  builder.addCase(addJobDocs, (state, action) => {
    state.job.jobDocs.push(action.payload);
  });

  // Remove Job Docs
  builder.addCase(removeJobDocs, (state, action) => {
    state.job.jobDocs.splice(
      state.job.jobDocs.findIndex(x => x.fileName === action.payload.filename),
      1);
  });

  // Set Job DOC PDF Operation
  builder.addCase(setJobDocPdfOperation, (state, { payload }) => {
    state.jobDocPdfOperation = payload;
  });

  // Add SW
  builder.addCase(addSW, (state, action) => {
    state.job.sws.push({
      ...action.payload,
      listGuid: uuidv4(),
      sortOrder: state.job.sws.length > 0
        ? Math.max.apply(null, state.job.sws.map(x => x.sortOrder)) + 1
        : 1,
    });
  });

  // Remove SW
  builder.addCase(removeSW, (state, action) => {
    state.job.sws.splice(
      state.job.sws.findIndex(sw => sw.listGuid === action.payload.listGuid),
      1);

    state.job.sws.forEach((sw, ix) => {
      sw.sortOrder = ix + 1;
    });
  });

  // Move SW
  builder.addCase(moveSW, (state, action) => {
    const dragSW = state.job.sws[action.payload.oldIndex];

    state.job.sws.splice(action.payload.oldIndex, 1);
    state.job.sws.splice(action.payload.newIndex, 0, dragSW);

    state.job.sws.forEach((sw, ix) => {
      sw.sortOrder = ix + 1;
    });
  });

  // Add Team Member
  builder.addCase(addTeamMember, (state, action) => {
    if (!state.job.team
      .find(t => t.email === action.payload.email)) {
      state.job.team.push(action.payload);
    }
  });

  // Remove Team Member
  builder.addCase(removeTeamMember, (state, action) => {
    state.job.team = state.job.team
      .filter(t => t.email !== action.payload.email);
  });

  // Set Is Saving
  builder.addCase(setIsSaving, (state, action) => {
    state.isSaving = action.payload;
  });

  // Set Save Error
  builder.addCase(setSaveError, (state, action) => {
    state.saveErrorMessage = action.payload;
  });

  // Set Get Job Error
  builder.addCase(setGetJobError, (state, action) => {
    state.getErrorMessage = action.payload;
  });

  // Set Save Success
  builder.addCase(setSaveSuccess, (state, action) => {
    state.wasSaveSuccessful = action.payload;
  });

  // Set List Data
  builder.addCase(setSWSearchResults, (state, action) => {
    state.searchSWData.swData = action.payload;
  });

  // Set SW List Search Text
  builder.addCase(setSearchText, (state, action) => {
    state.searchSWData.index = initialState.searchSWData.index;
    state.searchSWData.filterFields.searchTerm = action.payload;
  });

  // Add String Filter Item
  builder.addCase(setStringFilter, (state, action) => {
    switch (action.payload.attributeName) {
      case "maintenanceTaskIds": {
        state.searchSWData.filterFields.maintenanceTaskIdsFilter = action.payload.value;
        break;
      }
    }
  });

  // Clear Filter
  builder.addCase(clearFilter, state => {
    state.searchSWData.filterFields = initialState.searchSWData.filterFields;
    state.searchSWData.index = initialState.searchSWData.index;
  });

  // Add Filter Item
  builder.addCase(addFilterItem, (state, action) => {
    state.searchSWData.index = initialState.searchSWData.index;
    handleAddFilterItem(state,
      action.payload.metaDataName,
      action.payload.item);
  });

  // Add Filter Items
  builder.addCase(addFilterItems, (state, action) => {
    state.searchSWData.index = initialState.searchSWData.index;
    action.payload.items.forEach(item => {
      handleAddFilterItem(state,
        action.payload.metaDataName,
        item);
    });
  });

  // Set current Job View
  builder.addCase(setCurrentJobView, (state, action) => {
    state.currentView = action.payload;
  });

  // Remove filter item.
  builder.addCase(removeFilterItem, (state, action) => {
    state.searchSWData.index = initialState.searchSWData.index;
    switch (action.payload.metaDataName) {
      case "activityWorkflows": {
        state.searchSWData.filterFields.activityWorkflowsFilter =
          state.searchSWData.filterFields.activityWorkflowsFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "competencyElements": {
        state.searchSWData.filterFields.competencyElementsFilter =
          state.searchSWData.filterFields.competencyElementsFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "countries": {
        state.searchSWData.filterFields.countriesFilter =
          state.searchSWData.filterFields.countriesFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "equipment": {
        state.searchSWData.filterFields.equipmentFilter =
          state.searchSWData.filterFields.equipmentFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "equivalentSWs": {
        state.searchSWData.filterFields.equivalentSWsFilter =
          state.searchSWData.filterFields.equivalentSWsFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "languages": {
        state.searchSWData.filterFields.languagesFilter =
          state.searchSWData.filterFields.languagesFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "operatingEnvironments": {
        state.searchSWData.filterFields.operatingEnvironmentsFilter =
          state.searchSWData.filterFields.operatingEnvironmentsFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "owningOrgs": {
        state.searchSWData.filterFields.owningOrgsFilter =
          state.searchSWData.filterFields.owningOrgsFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "subBusinessLines": {
        state.searchSWData.filterFields.subBusinessLinesFilter =
          state.searchSWData.filterFields.subBusinessLinesFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "performingOrgs": {
        state.searchSWData.filterFields.performingOrgsFilter =
          state.searchSWData.filterFields.performingOrgsFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "safetyCategories": {
        state.searchSWData.filterFields.safetyCategoriesFilter =
          state.searchSWData.filterFields.safetyCategoriesFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "productCenters": {
        state.searchSWData.filterFields.productCentersFilter =
          state.searchSWData.filterFields.productCentersFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "businessRoles": {
        state.searchSWData.filterFields.businessRolesFilter =
          state.searchSWData.filterFields.businessRolesFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "epicEquipment": {
        state.searchSWData.filterFields.epicEquipmentFilter =
          state.searchSWData.filterFields.epicEquipmentFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "customers": {
        state.searchSWData.filterFields.customers =
          state.searchSWData.filterFields.customers
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "geoUnits": {
        state.searchSWData.filterFields.geoUnitsFilter =
          state.searchSWData.filterFields.geoUnitsFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "serviceTypes": {
        state.searchSWData.filterFields.serviceTypesFilter =
          state.searchSWData.filterFields.serviceTypesFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "tasks": {
        state.searchSWData.filterFields.tasksFilter =
          state.searchSWData.filterFields.tasksFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "taskAlignments": {
        state.searchSWData.filterFields.alignmentsFilter =
          state.searchSWData.filterFields.alignmentsFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "types": {
        state.searchSWData.filterFields.typesFilter =
          state.searchSWData.filterFields.typesFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "globalOrLocal": {
        state.searchSWData.filterFields.globalOrLocalFilter =
          state.searchSWData.filterFields.globalOrLocalFilter
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "owningPlants": {
        state.searchSWData.filterFields.owningPlants =
          state.searchSWData.filterFields.owningPlants
            .filter(x => x.code !== action.payload.item.code);
        break;
      }
      case "performingPlants": {
        state.searchSWData.filterFields.performingPlants =
          state.searchSWData.filterFields.performingPlants
            .filter(x => x.code !== action.payload.item.code);
        break;
      }
      case "workCenters": {
        state.searchSWData.filterFields.workCenters =
          state.searchSWData.filterFields.workCenters
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
      case "materials": {
        state.searchSWData.filterFields.materials =
          state.searchSWData.filterFields.materials
            .filter(x => x.guid !== action.payload.item.guid);
        break;
      }
    }
  });

  builder.addCase(setAssetDetails, (state, action) => {
    state.job.assetDetails = action.payload;
  });
});

export default manageJobReducer;

function handleAddFilterItem(state: IManageJobState,
  metaDataName: string,
  item: IMasterDataItem) {
  let arr: IMasterDataItem[] = [];

  switch (metaDataName) {
    case "activityWorkflows": {
      arr = state.searchSWData.filterFields.activityWorkflowsFilter;
      break;
    }
    case "competencyElements": {
      arr = state.searchSWData.filterFields.competencyElementsFilter;
      break;
    }
    case "countries": {
      arr = state.searchSWData.filterFields.countriesFilter;
      break;
    }
    case "equipment": {
      arr = state.searchSWData.filterFields.equipmentFilter;
      break;
    }
    case "equivalentSWs": {
      arr = state.searchSWData.filterFields.equivalentSWsFilter;
      break;
    }
    case "languages": {
      arr = state.searchSWData.filterFields.languagesFilter;
      break;
    }
    case "operatingEnvironments": {
      arr = state.searchSWData.filterFields.operatingEnvironmentsFilter;
      break;
    }
    case "owningOrgs": {
      arr = state.searchSWData.filterFields.owningOrgsFilter;
      break;
    }
    case "subBusinessLines": {
      arr = state.searchSWData.filterFields.subBusinessLinesFilter;
      break;
    }
    case "performingOrgs": {
      arr = state.searchSWData.filterFields.performingOrgsFilter;
      break;
    }
    case "safetyCategories": {
      arr = state.searchSWData.filterFields.safetyCategoriesFilter;
      break;
    }
    case "productCenters": {
      arr = state.searchSWData.filterFields.productCentersFilter;
      break;
    }
    case "businessRoles": {
      arr = state.searchSWData.filterFields.businessRolesFilter;
      break;
    }
    case "epicEquipment": {
      arr = state.searchSWData.filterFields.epicEquipmentFilter;
      break;
    }
    case "customers": {
      arr = state.searchSWData.filterFields.customers;
      break;
    }
    case "geoUnits": {
      arr = state.searchSWData.filterFields.geoUnitsFilter;
      break;
    }
    case "serviceTypes": {
      arr = state.searchSWData.filterFields.serviceTypesFilter;
      break;
    }
    case "tasks": {
      arr = state.searchSWData.filterFields.tasksFilter;
      break;
    }
    case "taskAlignments": {
      arr = state.searchSWData.filterFields.alignmentsFilter;
      break;
    }
    case "types": {
      arr = state.searchSWData.filterFields.typesFilter;
      break;
    }
    case "globalOrLocal": {
      arr = state.searchSWData.filterFields.globalOrLocalFilter;
      break;
    }
    case "owningPlants": {
      arr = state.searchSWData.filterFields.owningPlants;
      break;
    }
    case "performingPlants": {
      arr = state.searchSWData.filterFields.performingPlants;
      break;
    }
    case "workCenters": {
      arr = state.searchSWData.filterFields.workCenters;
      break;
    }
    case "materials": {
      arr = state.searchSWData.filterFields.materials;
      break;
    }
  }

  if (!arr.find(x => x.guid === item.guid)) {
    arr.push({
      ...item,
    });
  }
}