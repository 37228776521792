import { IOfflineState } from "./offlineTypes";
import { createReducer } from "@reduxjs/toolkit";
import {
  clearDeleteCacheOperation,
  setCachedJobIds,
  addCachedJobIds,
  removeCachedJobIds,
  setDeleteOperation,
  setIsAnythingAwaitingSync,
  setUploadCacheOperation,
  setUpdatedServiceWorker,
  setJobsAwaitingCompletions, 
  setJobsAwaitingCancellations,
  setCacheOperation,
  setConnectivity,
  setCacheTemplateOperation,
} from "./offlineActions";
import { Connectivity } from "interfaces/execution/executionInterfaces";

const initialState: IOfflineState = {
  cachedJobIds: [],
  isAnythingAwaitingSync: false,
  jobsAwaitingCompletion: [],
  jobsAwaitingCancellation: [],
  cacheOperation: undefined,
  cacheTemplateOperation: undefined,
  deleteOperation: undefined,
  isOnline: Connectivity.Online,
}

const offlineReducer = createReducer(initialState, builder => {
  // Set isOnline
  builder.addCase(setConnectivity, (state, action) => {
    state.isOnline = action.payload;
  });

  // Set Cache Operation Job Caching
  builder.addCase(setCacheOperation, (state, action) => {
    state.cacheOperation = action.payload;
  });

  // Set Cache Template Operation
  builder.addCase(setCacheTemplateOperation, (state, action) => {
    state.cacheTemplateOperation = action.payload;
  });

  // Set Cached Job Ids
  builder.addCase(setCachedJobIds, (state, action) => {
    state.cachedJobIds = action.payload.jobIds;
  });

  // Add Cached Job Ids
  builder.addCase(addCachedJobIds, (state, action) => {
    state.cachedJobIds = state.cachedJobIds
      .concat(action.payload.jobIds);
  });

  // Remove Cached Job Ids
  builder.addCase(removeCachedJobIds, (state, action) => {
    state.cachedJobIds = state.cachedJobIds
      .filter(jid => action.payload.jobIds.indexOf(jid) === -1);
  });

  // Set Delete Job Operation
  builder.addCase(setDeleteOperation, (state, action) => {
    state.deleteOperation = action.payload;
  });

   // Set Delete Job Operation Status
   builder.addCase(clearDeleteCacheOperation, (state) => {
    state.deleteOperation = undefined;
  });

  // Set Is Anything Awaiting Sync
  builder.addCase(setIsAnythingAwaitingSync, (state, action) => {
    state.isAnythingAwaitingSync = action.payload;
  });

  builder.addCase(setJobsAwaitingCompletions, (state, action) => {
    if(JSON.stringify(state.jobsAwaitingCompletion) !== JSON.stringify(action.payload.jobIds))
    {
      state.jobsAwaitingCompletion = [...action.payload.jobIds];
    }
  });

  builder.addCase(setJobsAwaitingCancellations, (state, action) => {
    state.jobsAwaitingCancellation = [...action.payload.jobIds];
  });

  // Set Upload Cache Operation
  builder.addCase(setUploadCacheOperation, (state, action) => {
    state.uploadOperation = action.payload;
  });

  // Set Updated Service Worker
  builder.addCase(setUpdatedServiceWorker, (state, action) => {
    state.updatedServiceWorker = action.payload;
  });
});

export default offlineReducer;